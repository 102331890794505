<!-- Start Our Loving Clients Area -->
<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Que dicen nuestros clientes acerca de <span>ExpertData</span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <!--<img src="assets/img/woman1.png" alt="image">-->

                <div class="feedback-desc">
                    <p>Expert Choice ha sido muy beneficioso en términos de los tiempos. Comprende el alcance de lo que nosotros queremos. Tenemos cercanía, con Rodrigo y su equipo, lo que hace que trabajemos con el arquitecto de soluciones, con su gerente comercial, en la medida que lo necesitemos. Y por otro lado, está también todo el conocimiento que ellos ya tienen del mercado. Por lo tanto, no nos cabe la menor duda de que es Expert Choice US LLC. Le va a ir extraordinariamente bien.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Anibal Jara</h3>
                        <span>Focal Point Telefónica Chile</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <!--<img src="assets/img/woman2.png" alt="image">-->

                <div class="feedback-desc">
                    <p>Expert Choice, con quien hemos estado trabajando a lo largo de cinco años por acá y a Jose Hernado lo conozco ya desde hace también, justamente como unos ocho años. Entonces la verdad es que nos sentimos muy cómodos, muy a gusto trabajando con esta empresa. Hemos tenido buena, una muy buena alianza y hemos tenido muy buenos resultados favorablemente.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Fernando Castrejón</h3>
                        <span>Gerente General Gea Internacional (Perú)</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <!--<img src="assets/img/man1.png" alt="image">-->

                <div class="feedback-desc">
                    <p>Acá no es solo un tema de calidad del software que ellos tienen, que es muy bueno, sino que lo que nosotros valoramos de gran medida es básicamente la disponibilidad del tiempo y la calidad de atención que tenemos cada vez que hemos tenido alguna, algún problema o alguna situación que debemos corregir de manera rápida.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Tirso Gonzalez</h3>
                        <span>Gerente de Riesgo Hites</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>La verdad en la llegada de este Expert Choice a la Ciudad de México y a todo el país, México a través de Antonio Zubillaga, ha sido una gran experiencia para nosotros, ya que para Lokal It, ha sido un socio estratégico para el proporcionamiento del crédito en base de esa herramientas y esos apoyos que nos han brindado. Nosotros estamos muy contentos que Expert Choice haya llegado a México porque hemos creado esas alianzas estratégicas y que a Lokal It Corporation le ha ayudado muchísimo en proporcionar créditos y sean muy efectivos a todos esos micro negocios.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Julián Alonso Macías</h3>
                        <span>CEO de Lokal It Corporation</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Así que hemos tenido una dilatada relación, una muy buena relación, no solo por lo dilatada y el deseo respecto a esta apertura en Estados Unidos es el mejor de los éxitos, el más grande éxito, una apertura interesante, un mercado nuevo. Experiencia tienen enfrentándose a distintos escenarios de volatilidad, de entropía. Así que mi deseo y el mejor de los éxitos, se puede decir. Creo que una linda oportunidad van a salir adelante.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Alejandro Enriquez</h3>
                        <span>Gerente Riesgo Coval</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Bueno por acá. Rodrigo Valdivieso CEO de Mundo Crédito la primera financiera 100% digital en Chile y quisiera aprovechar de mandarle un gran saludo a Rodrigo, a todo el equipo de Expert Choice y desearle lo mejor del mundo en su nuevo desafío. Y aprovechar esta mini grabación y contar un poco porque siempre hemos trabajado con ellos. Y la verdad que quisiera destacar siempre el compromiso, el profesionalismo y la dedicación que han tenido en todo su trabajo. Así que nuevamente felicitaciones y un gran abrazo. Adiós.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Rodrigo Valdivieso</h3>
                        <span>CEO Mundo Crédito</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Expert Choice que van estar partiendo este nuevo desafío abriendo sus puertas en Estados Unidos. Desde ya desearles el máximo, el máximo éxito en este trayecto que empiezan a cumplir a partir de ahora. Y bueno, desde la parte de Penta financiero, nuestros mejores deseos y colaboración es lo que ustedes necesiten. Un gran y fuerte abrazo para todos.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Andrea Hernández</h3>
                        <span>Gerente de Transformación Digital Penta Financiero</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Entregan soluciones que se ajustan a la medida de las necesidades de las distintas herramientas que uno va requiriendo la gestión de riesgo, la gestión de cobranza, que es lo que yo me he especializado y luego en mi paso por Telefónica y por AFP Provida.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Claudio Solis</h3>
                        <span>Gerente de Recaudación y Cobranza AFP Provida</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Considero que es un gran experto en temas de modelos crediticios y desarrollo de soluciones para riesgos. Es uno de mis grandes mentores en mi carrera profesional. Así que nada, quiero dejarles mi mensaje de éxito y de buena fortuna para lo que viene en el proyecto que están lanzando en Estados Unidos. Saludos.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Claudio Ceballos</h3>
                        <span>Director de Operaciones y de Riesgos Mills Capital Group</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Felicitarte porque es un nuevo paso en esta carrera que estás haciendo. Sos muy bueno en lo que hacés. Te he visto crecer, he visto que has hecho cosas en diferentes países de Latinoamérica o a través de Santander, también en España. Así que te felicito, te felicito, te deseo mucha suerte. Y a todos los que forman parte de esta familia Expert Choice también mucha suerte en este nuevo emprendimiento, en esta nueva salida y el mejor de los éxitos.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Fabian Cianciolo</h3>
                        <span>Markets Operations & Technology South Cone Head at Citi</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Haber tenido a Expert Choice S.A. como proveedores en distintas oportunidades, para mí ha sido una muy buena experiencia, tanto desde el punto de vista de la disposición de todo su equipo de trabajo, como del conocimiento técnico que han demostrado en todo lo relativo a Riesgo de Crédito, para mí es una empresa totalmente recomendable, ya que cumple con las expectativas que uno tiene al momento de apoyarse en expertos externos en materias técnicas.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Francisco Montesino</h3>
                        <span>Gerente de Riesgo Hites</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Expert Choice nos ha brindado, desde su incorporación como proveedor, un servicio excelente. Destaca la filosofía de servicio impregnada en cada una de sus acciones, su capacidad de estructurar respuestas a demandas específicas, en definitiva, los ejecutivos de Expert –Choice se ponen en nuestro lugar y reconocen nuestras necesidades.
                        Hemos tomado los servicios de Expert-choice porque aportan un valor agregado a la toma de decisiones, en nuestro caso, asociadas al otorgamiento de créditos masivos y pensamos seguir desarrollando otros proyectos de mucho interés para una industria cada vez más competitiva.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>William Sherrington</h3>
                        <span>Gerente de Riesgo Nissan - Marubeni</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Mi experiencia con la Empresa Expert Choice S. A., es de total satisfacción, ha sido un proveedor ágil, proactivo que me ha brindado un servicio realmente 7x24. Cuando he requerido desarrollos urgentes y de máxima prioridad, han respondido profesionalmente tanto en forma como fondo, por lo que, para mí, es una empresa totalmente recomendable.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Alejandro Henriquez</h3>
                        <span>Gerente de Riesgo Factoring Coval</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Our Loving Clients Area -->
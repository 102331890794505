<!-- Start Page Title Area -->
<!--<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>APIs</h2>
        </div>
    </div>
</div>-->
<!-- End Page Title Area -->

<!-- Apis details-->
<div class="ratio ratio-16x9">
    <iframe [src]="urlSafe" allowfullscreen></iframe>
</div>
<!-- End Apis details -->
<app-preloader></app-preloader>

<ng-container
    *ngIf="(location == '/auth/signup' || location == '/auth/signin' || location == '/error-404'); else second">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #second>
    <ng-container
        *ngIf="(location == '/home' || location == '/home-two' || location == '/home-three' || location == '/home-four' || location == '/home-five' || location == '/home-six'); else third">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </ng-container>
</ng-template>

<ng-template #third>
    <ng-container>
        <app-header-two></app-header-two>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </ng-container>
</ng-template>


<!-- <ng-container *ngIf="!(location == '/auth/signup' || location == '/auth/signin' || location == '/error-404')">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container> -->


<!-- If you want to show navigation in all pages -->
<!--
<ng-container>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container>
-->

<!--<div class="container">
    <div class="row">
        <div *ngIf="robot == true">
          <p >Ejemplo de ejecución HttpClient(asincrona) de google recaptcha
          </p><button (click)="getInfoRecaptcha()" class="btn btn-primary">
          Comprobación</button>
        </div>
    </div>
</div>
<div *ngIf="presionado == true">
  <p>Cargando ...</p>
</div>
 
<div *ngIf="robot == true">
    <p>De momento eres un robot, presiona el botón</p>
</div>
<div *ngIf="robot == false">
    <p>No eres un robot, Enhorabuena </p>
</div>-->
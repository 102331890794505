import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { RecaptchaService } from './service/recaptcha.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;

    public robot: boolean;
    public presionado: boolean;
    constructor(private router: Router, private httpService: RecaptchaService,  private recaptchaV3Service: ReCaptchaV3Service) {
        this.robot = true;
        this.presionado = false;
    }

    ngOnInit() {
        this.recallJsFuntions();
        this.robot = true;
        this.presionado = false;
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader-area').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $.getScript('../assets/js/conversation.js');
                $('.preloader-area').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    getInfoRecaptcha() {
        this.robot = true;
        this.presionado = true;
        this.recaptchaV3Service.execute('')
            .subscribe((token) => {
                const auxiliar = this.httpService.getTokenClientModule(token)
                auxiliar.subscribe({
                    complete: () => {
                        this.presionado = false;
                    },
                    error: () => {
                        this.presionado = false;
                        this.robot = true;
                        alert('Tenemos un problema, recarga la página para solucionarlo');
                    },
                    next: (resultado: Boolean) => {
                        if (resultado === true) {
                            this.presionado = false;
                            this.robot = false;
                        } else {
                            alert('Error en el captcha. Eres un robot')
                            this.presionado = false;
                            this.robot = true;
                        }
                    }
                });
            }
            );
    }

}

<!-- Start SaaS Main Banner -->
<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img src="assets/img/saas-shape/arrow.png" class="wow animate__animated animate__fadeInDown"
                                data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow animate__animated animate__fadeInLeft"
                                data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow bounceIn" data-wow-delay="0.6s"
                                alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png"
                                class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s"
                                alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow rotateIn" data-wow-delay="0.6s"
                                alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow animate__animated animate__fadeInUp"
                                data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow animate__animated animate__zoomIn"
                                data-wow-delay="0.6s" alt="monitor">

                            <!-- Main image -->
                            <img src="assets/img/saas-shape/main-image.png"
                                class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s"
                                alt="main-image.png">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Open APIs para evaluaciones de Riesgo de Crédito</h1>
                            <p>Estamos conectados con todas las fuentes de datos para la evaluación de Crédito de LATAM
                                y a Todas las Centrales de Riesgo; Deuda Super, Renta AFC, Poder Judicial, Sunat, DIAN,
                                SRI, SII, Quiebras, Ofac, PEP, Etc. todos los datos obtenidos a solo un click del
                                ingreso de datos de autentificación del cliente.</p>

                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/" fragment="Contacto" class="default-btn">
                                        <i class="bx bxs-hot"></i>
                                        Llamanos!
                                        <span></span>
                                    </a>

                                    <!--<a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> 
                                        <i class='bx bxs-right-arrow'></i> Watch Video 
                                    </a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start Features Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc" id="Servicios">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Características de Nuestro Servicio</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <h3>Fast and Optimized</h3>
                    <p>Desarrollo Optimizado: Desarrollado con las últimas tecnologías y siguiendo las buenas prácticas
                        de programación.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <h3>Full Security</h3>
                    <p>Seguridad Centralizada: Es posible consumir cualquiera de los servicios con una única
                        autenticación.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <h3>Time Saving</h3>
                    <p>Conexión Transparente. Positividad de acceder a múltiples fuentes de datos, desde un único
                        lugar.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <h3>Easily Manage</h3>
                    <p>Interfaces Propias: Gracias a la arquitectura, es posible que nuestros clientes creen sus propias
                        interfaces de usuario.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-lock-open bg-00d280'></i>
                    <h3>Quick Access</h3>
                    <p>Modelo de Servicios: Listo para usar, sin necesidad de instalar, mantener y actualizar hardwares
                        y software.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-droplet-half bg-ff612f'></i>
                    <h3>Drag and Drop</h3>
                    <p>Independencia: Su implementación, escalado y actualización se hace de manera independiente.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/home-saas/feature1.png" alt="image">
                </div>
            </div>

            <div class="services-content it-service-content">
                <div class="content">
                    <div class="features-inner-content">
                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".1s">
                            <i class='bx bx-phone-call bg-13c4a1 blt-radius-0'></i>
                            <h3>Evaluaciones de Crédito con Cero Riesgo</h3>
                            <p>Con ExpertData, usted podrá integrar a sus plataformas todas las fuentes de evaluación de
                                Riesgo, inferir Renta, realizar una oferta exacta.</p>
                        </div>

                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".2s">
                            <i class='bx bx-gift bg-6610f2 blt-radius-0'></i>
                            <h3>Mejoras en la Gestión de Cobranzas</h3>
                            <p>Nuestras fuentes de datos, le permiten generar Modelos de Cobranzas, Collection Scoring,
                                para un mejor recupero.</p>
                        </div>

                        <div class="features-item wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                            <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                            <h3>Evaluación de Pymes e Informales</h3>
                            <p>Nuestras fuentes de datos pueden "Ver", lo que los modelos tradicionales no. Que sus
                                plataformas de evaluación cuenten con toda la data.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape9">
        <img src="assets/img/shape/9.png" alt="image">
    </div>
</section>
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="ptb-100 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2 class="mb-2">Integración Transparente con Todos sus Sistemas</h2>
            <p>Integración con Todas las Plataformas de Evaluación de Riesgo de Crédito, integrables a sus Applicant y/o
                Behavios Scoring.</p>
        </div>
    </div>

    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/feature2.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">01</span>
                        <h3>Integralo ahora YA!</h3>
                        <p>Revisa nuestro Catálogo de APIs e integralas de inmediato a tus sistemas, son totalmente
                            agnósticas a tus tecnologías. Nuestro equipo de soporte experto, estará feliz de resolver
                            todas tus dudas o consultas.</p>
                        <p style="color: #ff612f;">Tecnologías Utilizadas:</p>
                        <ul>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Spring Boot versión 2.5
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Java versión 11
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Angular versión 12
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Full AWS
                            </li>
                        </ul>
                        <a routerLink="/" fragment="Contacto" class="default-btn black-btn">
                            <i class='bx bxs-arrow-to-right'></i>
                            Te Ayudamos!
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s" id="ApiScore">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">02</span>
                        <h3>Integre la API de <span style="color: #ff612f;">Score ExpertData</span></h3>
                        <p>La API Score de ExpertData, le permite automatizar sus resoluciones de créditos, incorporando
                            en una sola variable (1 al 999) la ponderación exacta de cada variable, según su negocio.
                        </p>
                        <ul>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Apruebe, Rechace o Zona Gris
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Anticipe deterioros de mercados
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Reduzca el número de analistas de crédito
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Incorpore a sus evaluaciones; RLB, SVM o IA
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Procesos BATCH, de Oboarding o Evaluaciones En Línea
                            </li>
                        </ul>
                        <a routerLink="/" fragment="Contacto" class="default-btn black-btn">
                            <i class='bx bxs-arrow-to-right'></i>
                            Pruebala!
                            <span></span>
                        </a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/home-saas/feature3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="overview-item wow animate__animated animate__fadeInUp" data-wow-delay=".6s" id="Nosotros">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/home-saas/feature4.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">03</span>
                        <h3>Acerca de Nosotros</h3>
                        <p>Somos un equipo interdisciplinario de expertos en el sector financiero y profesionales Senior
                            en Informática, Estadística y IA. <br>
                            Tenemos equipos de soporte en los principales países de la Región, somos entusiastas,
                            entretenidos y apasionados.</p>
                        <ul>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Equipo de desarrollo TI, con basta experiencia financiera
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Team Data Science, expertos en Estadística y IA
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Departamento legal. En MX, CL. CO, EC, US, AR, PE y Otros
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Departamento comercial, atento a tus necesidades
                            </li>
                            <li>
                                <i class='bx bx-badge-check'></i>
                                Tenemos experiencia de más de 15 años integrando la data de América
                            </li>
                        </ul>
                        <a routerLink="/" fragment="Contacto" class="default-btn black-btn">
                            <i class='bx bxs-arrow-to-right'></i>
                            Contactanos!
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100" id="Contacto">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>En que te podemos ayudar?</h3>
                        <p>Somos un equipo de expertos en Riesgo de Crédito, que te podemos apoyar en todo el proceso de
                            mejoras de tus evaluaciones de Crédito.</p>
                        <p>Brindamos servicios, asesorías y consultorías a las principales instituciones financieras de
                            la región. Nuestro equipo estará gustoso de sostener una reunión contigo y contarte como
                            puedes hacer mejores evaluaciones de Riesgo de Crédito o ayudarte a incursionar en nuevos
                            mercados.</p>
                        <p><strong>Somos Expertos en:</strong></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Banca y Financieras</li>
                            <li><i class='bx bx-badge-check'></i> Telecomunicaciones y Energía</li>
                            <li><i class='bx bx-badge-check'></i> Factoring y Confirming</li>
                            <li><i class='bx bx-badge-check'></i> Financiamiento Vehicular y Otras</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Escríbenos, Conversemos un Café!</h3>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" [formControl]="name" name="name" id="name"
                                            class="form-control" placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" [formControl]="email" id="email"
                                            class="form-control" placeholder="Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" [formControl]="phone_number"
                                            id="phone_number" class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" [formControl]="msg_subject"
                                            id="msg_subject" class="form-control" placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" [formControl]="message"
                                            id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <span class="span-envio">{{notificacion}}</span>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="button" (click)="enviarCorreo()" class="default-btn"><i
                                            class='bx bxs-paper-plane'></i>Enviar Mensaje<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contáctanos al número de teléfono o email</h3>
                    <h2>
                        <a href="tel:+17864327800">+1 786-432-7800</a>
                        <span>OR</span>
                        <a href="mailto:hello@expertdata.credit">hello@expertdata.credit</a>
                    </h2>

                    <div class="funfacts-inner">
                        <div class="row">
                            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                                <div class="single-funfacts">
                                    <h3><span class="odometer" data-count="180">00</span><span
                                            class="sign-icon">k</span></h3>
                                    <p>Downloaded</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                                <div class="single-funfacts">
                                    <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span>
                                    </h3>
                                    <p>Feedback</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                                <div class="single-funfacts">
                                    <h3><span class="odometer" data-count="500">00</span><span
                                            class="sign-icon">+</span></h3>
                                    <p>Workers</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                                <div class="single-funfacts">
                                    <h3><span class="odometer" data-count="70">00</span><span class="sign-icon">+</span>
                                    </h3>
                                    <p>Contributors</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="contact-cta-box">
                        <h3>Tiene alguna consulta?</h3>
                        <p>Don't hesitate to contact us.</p>
                        <a routerLink="/" fragment="Contacto" class="default-btn"><i
                                class="bx bxs-edit-alt"></i>Contactanos!<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Video Presentation Area -->
<!--<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Watch this video presentation to know more</h2>
        </div>



    </div>


</section>-->
<!-- End Video Presentation Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/home-saas/feature5.png" alt="image">
                </div>
            </div>

            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>¿Necesitas más Ayuda?<br>
                            Claro, que la Tenemos!</h2>
                        <p>Si requieres que te apoyemos con las plataformas de evaluación, te podemos apoyar desde el On
                            Boarding, Evaluación, Resolución, Curse y Formalización Remota de Tus Operaciones.</p>
                        <p style="color: #ff612f;">Nuestras plataformas:</p>
                        <ul>
                            <li>
                                <i class="bx bx-badge-check"></i>
                                Potentes Motores Decisionales
                            </li>
                            <li>
                                <i class="bx bx-badge-check"></i>
                                Portales de Autoatención Plug and Play
                            </li>
                            <li>
                                <i class="bx bx-badge-check"></i>
                                Custumer Digital Profile
                            </li>
                            <li>
                                <i class="bx bx-badge-check"></i>
                                HUB de Datos
                            </li>
                            <li>
                                <i class="bx bx-badge-check"></i>
                                Plataformas comerciales Inteligentes
                            </li>
                        </ul>

                        <a routerLink="/" fragment="Contacto" class="default-btn black-btn">
                            <i class="bx bxs-arrow-to-right"></i>
                            Contactanos!
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape9">
        <img src="assets/img/shape/9.png" alt="image">
    </div>
</section>
<!-- End Services Area -->

<app-testimonios></app-testimonios>

<!--<app-pricing-plan id="Precios"></app-pricing-plan>-->

<!-- Start FAQ Area -->
<!--<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Spacle</span></h2>

                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End FAQ Area -->

<app-clients id="Clientes"></app-clients>

<app-free-trial></app-free-trial>

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-home-seven',
  templateUrl: './home-seven.component.html',
  styleUrls: ['./home-seven.component.scss']
})
export class HomeSevenComponent implements OnInit {

  constructor(private emailService: EmailService) { }

  name: FormControl;
  email: FormControl;
  phone_number: FormControl;
  msg_subject: FormControl;
  message: FormControl;
  notificacion: String;
  ngOnInit(): void {
    this.name = new FormControl('', Validators.required);
    this.email = new FormControl('', Validators.required);
    this.phone_number = new FormControl('', Validators.required);
    this.msg_subject = new FormControl('', Validators.required);
    this.message = new FormControl('', Validators.required);
    this.notificacion = '';
  }

  enviarCorreo() {
    if (this.name.value === '') {
      this.notificacion = 'Name no puede estar vacio';
    } else if (this.email.value === '') {
      this.notificacion = 'Email no puede estar vacio';
    } else if (this.phone_number.value === '') {
      this.notificacion = 'Phone no puede estar vacio';
    } else if (this.msg_subject.value === '') {
      this.notificacion = 'Subject no puede estar vacio';
    } else if (this.message.value === '') {
      this.notificacion = 'Message no puede estar vacio';
    } else {
      const contenido = "Un cliente a enviado la siguiente información: <br><br>"
        + "Nombre: " + this.name.value + "<br>"
        + "Correo: " + this.email.value + "<br>"
        + "Teléfono: " + this.phone_number.value + "<br>"
        + "Asunto: " + this.msg_subject.value + "<br>"
        + "Mensaje: " + this.message.value + "<br>"
        + "<br><br>"
        + "-- Este E-mail se ha enviado desde un formulario de contacto del sitio Web de ExpertData (https://expertdata.credit/)";
      const body = {
        "from": "hello@expertdata.credit",
        "to": "hello@expertdata.credit",
        "subject": "Contacto para información",
        "content": contenido
      };
      this.emailService.enviarCorreo(body).subscribe(data => {
        if (data.status == 200) {
          this.notificacion = 'Correo Enviado';
        } else {
          this.notificacion = 'Error de envio de Correo';
        }
      },
        error => {
          console.log(error);
        })

      this.name = new FormControl('', Validators.required);
      this.email = new FormControl('', Validators.required);
      this.phone_number = new FormControl('', Validators.required);
      this.msg_subject = new FormControl('', Validators.required);
      this.message = new FormControl('', Validators.required);
    }
  }

}
